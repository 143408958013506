/* @tailwind base; */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Prata&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
  html {
    font-family: "Raleway", sans-serif serif;
  }
}

.menu-item:after {
  display:block;
  content: '';
  border-bottom: solid 2px #5c1090;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}
.menu-item:hover:after { transform: scaleX(1); }

#home-section {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

@media only screen and (max-width: 600px) {
  #home-section {
    background-size: contain;
    background-position: top;
    background-attachment: scroll;
  }
}