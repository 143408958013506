@media print {
  .noprint {
    display: none;
  }
  @page {
    size: portrait;
    margin: 0mm;
  }
}
#box {
  border: 2px solid #000;
  border-radius: 105;
  width: 200px;
  height: 100px;
  background-color: #fff;
}
